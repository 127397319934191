<template>
  <a
    :href="url"
    v-if="info >= 0"
    :to="{ name: targetName }"
    class="tile is-parent"
    @click.stop.prevent="navigateToTarget"
  >
    <article class="tile is-child box">
      <p class="title is-size-6">{{ info }}</p>
      <p class="subtitle is-size-6">{{ subtitle }}</p>
    </article>
  </a>
</template>


<script>
export default {
  name: "CardInfo",
  props: ["info", "subtitle", "targetName", "appName"],
  computed: {
    url() {
      let route = this.$router.resolve({ name: this.targetName });
      return route.href;
    }
  },
  methods: {
    navigateToTarget() {
      let routeTo = { name: this.targetName };
      let routerNames = [
        "ujian-list", "aktivitas-list"];
      routeTo.params = {};
      if (this.targetName === "pencapaian-nilai") {
        routeTo.params.dinilai = "Belum";
      } else if (routerNames.includes(this.targetName)) {
        routeTo.params.verifikasi = "Belum";
      }
      this.$router.push(routeTo);
    }
  }
};
</script>


<style scoped lang="scss">
.is-parent {
  padding: 0.2rem !important;
}

.is-child {
  padding: 0.6rem !important;
}
</style>